import { OptimizelyContext } from '@optimizely/react-sdk'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { CMS } from '~/cms/types'
import { JOIN_556_VARIANTS } from '~/experiments/JOIN-440-462-email-skip/EmailSkipExperiment.types'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import GetStartedPromoLander from '~/routes/GetStarted/PromoLander'
import { cleanJsonStrings } from '~/utils/unicode'

/**
 * This value should remain constant even if this lander is hosted in a new slug
 * See: https://github.com/butcherbox/butcherbox-frontend/pull/937 for more context
 */
const UNIQUE_PAGE_ID = 'get-started'

export default function () {
  const { optimizely } = React.useContext(OptimizelyContext)
  const [isMounted, setIsMounted] = React.useState(false)
  const isOptimizelyReady = optimizely.isReady()

  const [shouldRedirect] = useOptimizelyFeature('join-1004-cyo_backup')
  if (shouldRedirect) {
    const searchParams = window.location.search || ''
    window.location.replace(
      `https://shop.butcherbox.com/partners/cyo-get-started${searchParams}` //TODO: need to get actual page
    )
  }

  // Render a page when Optimizely is ready, fallback incase optimizely fails
  React.useEffect(() => {
    if (!isMounted) {
      if (isOptimizelyReady) {
        setIsMounted(true)
      } else if (!isMounted) {
        setTimeout(() => {
          if (isMounted) return
          setIsMounted(true)
        }, 1000)
      }
    }
  }, [optimizely, isOptimizelyReady, isMounted])

  return (
    <>
      {shouldRedirect || !isMounted ? <LoadingSpinner /> : <GetStartedPage />}
    </>
  )
}

const GetStartedPage = () => {
  const [emailSubmissionVariant] = useOptimizelyExperiment(
    'join-556__retest_skip_email_gate'
  ) as [JOIN_556_VARIANTS, null, null]

  const { pageData, memberPerksImage } = cleanJsonStrings(
    useStaticQuery<{
      pageData: CMS.ProtectedPage<CMS.ProductOfferLander>
      memberPerksImage: {
        childImageSharp: { fluid: any }
      }
    }>(graphql`
      query {
        pageData: contentfulProtectedPage(
          pageId: { eq: "signup-interstitial" }
        ) {
          ...ProtectedPage
        }

        memberPerksImage: file(
          relativePath: { eq: "steak_on_serving_board_crop_center.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1441, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  )

  return (
    <GetStartedPromoLander
      allowSkipEmailGate={
        emailSubmissionVariant &&
        emailSubmissionVariant === JOIN_556_VARIANTS.SKIP_EMAIL
      }
      // destinationFrame={destinationURL}
      memberPerksImage={memberPerksImage?.childImageSharp?.fluid || {}}
      pageData={pageData}
      uniquePageId={UNIQUE_PAGE_ID}
    />
  )
}
